import { setValue, getValue } from './storage.service';

const appStoreKey = "APP_STATE";

export const storageMiddlewareFactory = (actionTypes, selector) => ({ getState }) => {
  return next => action => {
    const result = next(action);
    if (actionTypes.includes(action.type)) {
      setValue(appStoreKey, getState());
    }
    return result;
  };
};

export const reHydrateStore = () => {
  return getValue(appStoreKey) || undefined;
};