import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect'
import ReactRough from 'react-rough';
import * as configLayout from '../../config/layout';
import { getPointFromIndex, getIndexByPoint, addAvaibleSelector } from 'shared/src/game/maths';
import * as config from 'shared/src/game/config';
import { hitAction } from '../../modules/game/game.actions';
import Cell from './Cell';
import WinGraph from './WinGraph';

const { xCount, yCount } = config.cells;
const { width, height } = configLayout.size;
const wDiff = width / xCount;
const hDiff = height / yCount;


const Board = (props) => {
  const { cells, currentPayer, hitAction, isActive, status } = props;
  const win = status.includes('win') ? status.split('.')[1]: null;

  const onClick = (event) => {
    if (!isActive) {
      return;
    }

    const x = Math.floor(event.nativeEvent.offsetX / wDiff);
    const y = Math.floor(event.nativeEvent.offsetY / hDiff);
    const index = getIndexByPoint(x, y);
    console.log(x, y);
    if (cells[index].available) {
      hitAction(currentPayer, index);
    }
  }

  return (
    <div onClick={onClick}>
      <ReactRough width={width} height={height} renderer='svg'>
        {cells.map((cell, index) => {
          return <Cell key={index} index={index} {...cell}></Cell>
        })}
        {win && <WinGraph player = {win} ></WinGraph>}
      </ReactRough>
    </div>
  );
}

const mapStateToProps = createSelector(
  state => state.board,
  ({ cells, currentPayer, users, status }) => {
    const isActive = users[currentPayer].type === 'user';
    return {
      cells: addAvaibleSelector(cells, currentPayer),
      currentPayer,
      isActive,
      status
    };
  }
);



const mapDispatchToProps = dispatch => ({
  hitAction: (player, point) => dispatch(hitAction(player, point))
});

export default connect(mapStateToProps, mapDispatchToProps)(Board);
