import React from 'react';
import { Rectangle, Line, Circle } from 'react-rough';
import * as configLayout from '../../config/layout';
import { getPointFromIndex } from 'shared/src/game/maths';
import { cells } from 'shared/src/game/config';

const { xCount, yCount } = cells;
const margin = 3;
const { width, height } = configLayout.size;
const wDiff = width / xCount;
const hDiff = height / yCount;

const Cell = (props) => {
  const { player, isAlive, available, index } = props;
  const { x, y } = getPointFromIndex(index);
  // const { player, isAlive } = cell;

  const fill = isAlive ? 'transparent' : configLayout.players[player].color;
  const fillAvailable = available ? configLayout.players[available].colorAvailable : 'transparent';
  const stroke = isAlive ? configLayout.players[player].color : '#fff';

  const isNone = player === 'none';
  const isCircle = player === 'red' ^ isAlive;

  return <g style={{ pointerEvents: 'none' }}>
    <Rectangle
      height={hDiff - 2 * margin}
      width={wDiff - 2 * margin}
      x={x * wDiff + margin}
      y={y * hDiff + margin}
      fill={fill}
      hachureGap={5}
      fillWeight={3} />

    {(!!available) ?
      <Rectangle
        height={hDiff - 2 * margin}
        width={wDiff - 2 * margin}
        x={x * wDiff + margin}
        y={y * hDiff + margin}
        fill={fillAvailable}
        stroke={null}
        hachureGap={5}
        fillWeight={3} /> : null}

    {!isNone && ((!isCircle) ?
      <g>
        <Line
          x1={x * wDiff + margin * 4}
          y1={y * hDiff + margin * 4}
          x2={(x + 1) * wDiff - margin * 4}
          y2={(y + 1) * hDiff - margin * 4}
          strokeWidth={4} stroke={stroke} />
        <Line
          x1={(x + 1) * wDiff - margin * 4}
          y1={y * hDiff + margin * 4}
          x2={x * wDiff + margin * 4}
          y2={(y + 1) * hDiff - margin * 4}
          strokeWidth={4} stroke={stroke} />]
          </g>
      :
      <g>
        <Circle
          x={(x + 0.5) * wDiff}
          y={(y + 0.5) * hDiff}
          diameter={wDiff - 6 * margin}
          strokeWidth={4} stroke={stroke} />
      </g>)
    }

  </g>
}

export default Cell;
