import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
  watchTournamentGameStatus,
  startChannel,
} from "../../modules/online/online.actions";
import * as configLayout from "../../config/layout";

const tdStyle = {
  margin: "-4px -2px",
  display: "block",
  fontSize: "1.5em",
  lineHeight: "1em",
};

const headTdStyle = {
  writingMode: "vertical-lr",
  transform: "rotate(-25deg) translate(0, -10px)",
  transformOrigin: "50% 100%",
  textAlign: "right",
  color: configLayout.players.red.color,
};

const rowLabelStyle = {
  color: configLayout.players.blue.color,
};

function StandingsTable(props) {
  const { tournament } = props;
  const players = tournament.players;
  const statusToIconMap = {
    waiting: ".",
    scheduled: ":",
    joining: "|",
    playing: ">",
    done: "+",
  };

  const getColor = (winner) =>
    winner ? configLayout.players[winner].color : undefined;

  const gameStatusMap = useMemo(() => {
    return tournament.games.reduce((acc, game) => {
      const i = game.players[0].index;
      const j = game.players[1].index;
      const winner = game.winner
        ? game.winner.index === game.players[0].index
          ? "blue"
          : "red"
        : undefined;

      const reverseWinner = game.winner
        ? game.winner.index === game.players[0].index
          ? "red"
          : "blue"
        : undefined;

      acc[`${i}${j}`] = {
        status: game.status,
        winner,
      };
      acc[`${j}${i}`] = {
        status: game.status,
        winner: reverseWinner,
      };
      return acc;
    }, {});
  }, [tournament.games, tournament.players]);

  return (
    <>
      <h3>#standings table</h3>

      <div style={{ display: "inline-block" }}>
        <table>
          <tr>
            <td></td>
            {players.map((player, i) => (
              <td key={i} style={headTdStyle}>{`${i + 1}. ${
                player.username
              }`}</td>
            ))}
          </tr>
          {players.map((player, i) => {
            return (
              <tr key={i}>
                <td style={rowLabelStyle}>{i + 1}.</td>
                {players.map((playerJ, j) => {
                  const game = gameStatusMap[`${i}${j}`];
                  return (
                    <td key={j}>
                      {i === j ? (
                        ""
                      ) : (
                        <wired-card title={game.status} style={tdStyle}>
                          <span style={{ color: getColor(game.winner) }}>
                            {statusToIconMap[game.status]}
                          </span>
                        </wired-card>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
});

const mapToProps = (state) => ({
  serverStatus: state.socket.serverStatus,
  username: state.app.username,
  tournament: state.tournament,
});

export default connect(mapToProps, mapDispatchToProps)(StandingsTable);
